import React from 'react'
import Zendesk from 'react-zendesk';
import logo from '../../../assets/svg/logo.svg'
import styles from './Header.module.scss'
function Header() {
    const ZENDESK_KEY = "9f515eaf-b453-4e8e-86f8-e6fd6d956dc7";
    const setting = {
        color: {
            theme: "#fb3333",
            launcher: "#fb3333",
            launcherText: "#ffffff",
            button: "#fb3333",
            buttonText: "#ffffff",
            resultLists: "#691840",
            header: "#fb3333",
            articleLinks: "#FF4500",
            bypassAccessibilityRequirement: true,
            widgetTitle: "#fffff",
          },
        launcher: {
          chatLabel: {
            "en-US": "Need Help"
          }
        },
        contactForm: {
          fields: [
            { id: "description", prefill: { "*": "My pre-filled description" } }
          ]
        }
      };
      const App = () => {
        return <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />;
      };
      
    return (
        <div>
            <div className={styles.Background}>
                <div className={styles.Image}>
                    <img src={logo} />
                </div>
                <div className={styles.Button}>
                    <button >
                        <a href="javascript:$zopim.livechat.window.show();"><div>Contact Us</div></a>
                    </button>
                </div>
                <App/>
            </div>
        </div>
    )
}

export default Header
