import React, { useEffect, useState } from 'react'
import historyVehicle from '../../../assets/svg/history-vehicle.svg'
import historyOwner from '../../../assets/svg/history-owner.svg'
import historyTime from '../../../assets/svg/history-time.svg'
import { IBooking } from '../../../@customTypes/booking'
import styles from './HistorySpace.module.scss'
interface HistorySpaceProps {
    renter: IBooking
}
function HistorySpace({ renter }: HistorySpaceProps) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const [time, setTime] = useState('')
    const [progressTime, setProgressTime] = useState(0)
    const helper:{[key:string]:string} = {
        completed: "Completed",
        cancelled: "Cancelled",
        inProgress: "InProgress",
    }
      
    useEffect(() => {
        setTime(new Date(new Date(renter?.endTimestamp).getTime() - renter.startTimestamp).getUTCHours() + " hr and " + new Date(new Date(renter?.endTimestamp).getTime() - renter.startTimestamp).getMinutes() + " mins")
        setProgressTime(renter?.transactionSummary.rawPrice+renter?.transactionSummary.tax);
    }, [])
    return (
        <div>
            {
                renter.status=== 'completed.success' && renter.endTimestamp!== 0 ?
                <div className={`${styles.Background} container `}>
            <div className={`row`}>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex}`}>
                    <img src={historyVehicle} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={` ${styles.ColorBlack}`}>{renter?.vehicleInfo?.name}</div>
                        <div className={styles.ColorGrey}>{renter?.vehicleInfo?.plateNumber}</div>
                    </div>
                </div>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                    <img src={historyTime} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={styles.ColorBlack}> {renter.spaceInfo.parkingRate.currency} {progressTime.toFixed(2)}</div>
                        <div className={styles.ColorGrey}>{time}</div>
                    </div>
                </div>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                    <img src={historyOwner} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={styles.ColorBlack}>{renter?.renterInfo?.firstName} {renter?.renterInfo?.lastName}</div>
                        <div className={`${styles.ColorGrey} ${styles.Address}`}>{renter?.spaceInfo?.addressInfo?.fullAddress}</div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className={`${styles.ColorGrey} col-12`}>
                {helper[renter?.status === 'completed.success' ? "completed" : "cancelled"]} on {monthNames[new Date(renter?.endTimestamp).getMonth()]} {new Date(renter?.endTimestamp).getUTCDate()}, {new Date(renter?.endTimestamp).getFullYear()}
                </div>
            </div>
        </div> :
        <div className={`${styles.Background} container `}>
        {
            <div>
                <div className={`row`}>
            <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex}`}>
                <img src={historyVehicle} alt="alt" width={49} height={49} />
                <div className="px-2">
                    <div className={` ${styles.ColorBlack}`}>{renter?.vehicleInfo?.name}</div>
                    <div className={styles.ColorGrey}>{renter?.vehicleInfo?.plateNumber}</div>
                </div>
            </div>
            {
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                <img src={historyTime} alt="alt" width={49} height={49} />
                <div className="px-2">
                    <div className={`${styles.ColorBlack} pt-3`}>Parking cancelled</div>
                </div>
            </div>
            }
            
            <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                <img src={historyOwner} alt="alt" width={49} height={49} />
                <div className="px-2">
                    <div className={styles.ColorBlack}>{renter?.renterInfo?.firstName} {renter?.renterInfo?.lastName}</div>
                    <div className={`${styles.ColorGrey} ${styles.Address}`}>{renter?.spaceInfo?.addressInfo?.fullAddress}</div>
                </div>
            </div>
        </div>
        <div className="row ">
        <div>Cancelled on {monthNames[new Date(renter?.dateUpdated).getMonth()]} {new Date(renter?.dateUpdated).getUTCDate()}, {new Date(renter?.dateUpdated).getFullYear()}</div>
        </div>
            </div>
        }
        
        
    </div>
            }
        </div>
    )
}
export default HistorySpace
