
import marker from '../../assets/svg/marker.svg'
import style from '../../static/mapStyles'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
export interface Props {
    coord: any
}
function googleMap({ coord }: Props) {

    function Map() {

        return (
            <GoogleMap
                defaultZoom={15}
                options={{ styles: style }}
                defaultCenter={coord}
            >
                <Marker position={coord} icon={marker} />
            </GoogleMap>
        )
    }

    const WrapMap = withScriptjs(withGoogleMap(Map)) as any;

    return (
        <div style={{ width: '100%', height: '30vh', marginBottom: '20px' }}>
            <WrapMap googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDAzzlrK6s9Mo3vfnltj_8pen3xYbJUc9k`}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
            />

        </div>
    )
}
export default googleMap