import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IAlbum, ISpace } from "../../@customTypes/space";
import GoogleMapView from "../../components/maps/GoogleMapView";
import { getSlots, getSpace } from "../../endpoints/space";
import styles from "./SpaceDetails.module.scss";
import noPhoto from "../../assets/svg/no-photo.svg";
import Modal from "../../components/Common/Modal/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CurrentSpaces from "../../components/Common/CurrentSpaces/CurrentSpaces";
import HistorySpaces from "../../components/Common/HistorySpaces/HistorySpaces";
import rating from "../../assets/png/rating.png";
import chargingIcon from "../../assets/icons/charging-icon.png";
import ParkingType from "../../components/Common/ParkingType/ParkingType";
import ChargingType from "../../components/Common/ChargingType/ChargingType";
import ScheduledSpaces from "../../components/Common/ScheduledSpaces/ScheduledSpaces";
function SpaceDetails() {
  const router = useHistory();
  let { spaceId } = useParams<{ spaceId: string }>();
  const [space, setSpace] = useState<ISpace>();
  const [slot, setSlot] = useState<number>();
  const [availability, setAvailability] = useState("24/72");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const Weekends = ["Saturday", "Sunday"];
  const DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const sortDays = (days: string[]) => {
    let sortedDays = "";

    DAYS.map((day, index) => {
      days.includes(day) && (sortedDays += day + ", ");
    });

    return sortedDays.slice(0, -2);
  };

  useEffect(() => {
    getSpace(spaceId, token).then((res) => {
      if (res) {
        setSpace(res);
      } else {
        router.push("/notfound");
      }
      if (res?.availability?.selectedDays?.length) {
        switch (res?.availability?.selectedDays?.length) {
          case 7:
            setAvailability("Everyday");
            break;
          case 5:
            let isWeekday = true;
            res?.availability?.selectedDays.map((day, index) => {
              if (day === Weekends[0] || day === Weekends[1]) isWeekday = false;
            });
            if (isWeekday) setAvailability("Weekdays");
            else setAvailability(sortDays(res?.availability?.selectedDays));
            break;
          case 2:
            let isWeekend = true;
            res?.availability?.selectedDays.map((day, index) => {
              if (day !== Weekends[0] && day !== Weekends[1]) isWeekend = false;
            });
            if (isWeekend) setAvailability("Weekend");
            else setAvailability(sortDays(res?.availability?.selectedDays));
            break;
          default:
            setAvailability(sortDays(res?.availability?.selectedDays));
        }
      }
    });
    getSlots(spaceId, token).then((res: any) => {
      setSlot(res.count);
    });
  }, []);

  const [loadImage, setLoadImage] = useState(false);

  const handleOnclick = () => {
    if (space?.album.length) {
      setLoadImage(!loadImage);
    }
  };
  return (
    <div>
      {space?.addressInfo && (
        <GoogleMapView
          coord={{
            lat: space?.addressInfo.latitude,
            lng: space?.addressInfo.longitude,
          }}
        />
      )}
      <Modal showModal={loadImage} setShowModal={() => setLoadImage(false)}>
        <div>
          <div className={styles.ButtonCont}>
            <button
              className={styles.Button}
              onClick={() => setLoadImage(!loadImage)}
            >
              X
            </button>
          </div>
          <Carousel autoPlay infiniteLoop>
            {space?.album.map((as: IAlbum, key: any) => {
              return (
                <div key={key}>
                  <img src={as.url} alt="current" width={300} height={300} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </Modal>
      <div className="container">
        <div className={`row ${styles.PaddingContainer}`}>
          <div className={`col-12 col-sm-12 col-lg-4 ${styles.ImagePadding}`}>
            {space?.album.length ? (
              <img
                src={space?.album[0]?.url}
                alt="alt"
                height={250}
                width={250}
                className={styles.Album}
              />
            ) : (
              <img
                src={noPhoto}
                alt="alt"
                height={250}
                width={250}
                className={styles.Album}
              />
            )}
            <div>
              <button className={styles.BtnStyle} onClick={handleOnclick}>
                See more photos
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-8">
            <div className="row">
              <div
                className={`col-12 col-sm-12 col-lg-7  ${styles.FullAddress}`}
              >
                {space?.addressInfo.fullAddress}
              </div>
              <div
                className={`col-12  col-sm-12 col-lg-5 d-flex  ${styles.Price}`}
              >
                {space?.parkingRate.currency} {space?.parkingRate.value}/hr
                <div className="d-flex mt-1">
                  <div className={styles.ImageCircle}>
                    <ParkingType type={space?.type} />
                  </div>
                  <div>
                    {space?.supportedChargingPorts.length ? (
                      <img className={styles.ImageCircle} src={chargingIcon} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12 d-flex align-items-center">
                <img src={rating} width={16} height={16} />
                <div className={styles.MarginLeft}>
                  {space?.reviewSummary?.totalRatings
                    ? space?.reviewSummary?.totalRatings.toFixed(1)
                    : "No rating/s yet"}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {/* <div className="col-12 col-sm-12 col-lg-3">
                                <div className={styles.SubTitle}>Reservation Fee</div>
                                <div >{space?.reservationFee.currency} {space?.reservationFee.value}</div>
                            </div> */}
              <div className="col-12 col-sm-12 col-lg-3">
                <div className={styles.SubTitle}>No. of Slots</div>
                <div>{slot}</div>
              </div>
              <div className="col-12 col-sm-12 col-lg-3">
                <div className={styles.SubTitle}>Owner</div>
                <div>Private</div>
              </div>
              <div className="col-12 col-sm-12 col-lg-3">
                <div className={styles.SubTitle}>Charging Type</div>
                <div>
                  {space?.supportedChargingPorts.length
                    ? space?.supportedChargingPorts.map((res) => {
                        return (
                          <span className={styles.MarginRight}>
                            <ChargingType key={res} type={res.type} />
                          </span>
                        );
                      })
                    : "None"}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-lg-3">
                <div className={styles.SubTitle}>Availability</div>
                <div>{availability}</div>
                <div>
                  {space?.availability?.selectedTime?.start?.hour! < 10
                    ? "0" + space?.availability?.selectedTime?.start?.hour
                    : space?.availability?.selectedTime?.start?.hour}
                  :
                  {space?.availability?.selectedTime?.start?.minute! < 10
                    ? "0" + space?.availability?.selectedTime?.start?.minute
                    : space?.availability?.selectedTime?.start?.minute}{" "}
                  -
                  {space?.availability?.selectedTime?.end?.hour! < 10
                    ? " 0" + space?.availability?.selectedTime?.end?.hour
                    : " " + space?.availability?.selectedTime?.end?.hour}
                  :
                  {space?.availability?.selectedTime?.end?.minute! < 10
                    ? "0" + space?.availability?.selectedTime?.end?.minute
                    : space?.availability?.selectedTime?.end?.minute}
                </div>
              </div>
              <div className="col-12 col-sm-12  col-lg-3">
                <div className={styles.SubTitle}>Security</div>
                <div>{space?.security}</div>
              </div>
              <div className="col-12 col-sm-12 col-lg-6">
                <div className={styles.SubTitle}>Special Instructions</div>
                <div>{space?.instructions}</div>
              </div>
            </div>
            <div className="row mt-4">
              <div className={styles.Title}>Current Booking</div>
            </div>
            <div className="row mt-2">
              <CurrentSpaces spaceId={spaceId} token={token} />
            </div>
            <div className="row mt-4">
              <div className={styles.Title}>Scheduled Booking</div>
            </div>
            <div className="row mt-2">
              <ScheduledSpaces spaceId={spaceId} token={token} />
            </div>
            <div className="row mt-4">
              <div className={styles.Title}>History</div>
            </div>
            <div className="row mt-2">
              <HistorySpaces spaceId={spaceId} token={token} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaceDetails;
