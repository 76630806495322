import React, { useEffect, useState } from 'react'
import currentVehicle from '../../../assets/svg/current-vehicle.svg'
import currentOwner from '../../../assets/svg/current-owner.svg'
import currentTime from '../../../assets/svg/current-time.svg'
import { IBooking } from '../../../@customTypes/booking'
import styles from './ScheduledSpace.module.scss'
interface ScheduledSpaceProps {
    renter: IBooking
}
function ScheduledSpace({ renter }: ScheduledSpaceProps) {
    console.log("renter ",renter);
    const [scheduledDate,setScheduledDate] = useState<any>()
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const [time, setTime] = useState('')
    const [progressTime, setProgressTime] = useState(0)
    const [total, setTotal] = useState(0)
    useEffect(() => {
        setTime(new Date(new Date(renter?.scheduledDates[0]?.duration * renter?.scheduledDates.length)).getUTCHours() + " hr")

        setProgressTime(renter?.transactionSummary.rawPrice+renter?.transactionSummary.tax);

        if (renter?.status === 'inProgress.scheduled') {
            let fDate
            let lDate
            let minutes = new Date(renter?.scheduledDates[0].duration).getUTCMinutes() + ""
            if (new Date(renter?.scheduledDates[0].duration).getUTCMinutes() < 10) {
              minutes = '0' + new Date(renter?.scheduledDates[0].duration).getUTCMinutes()
            }
            if (renter?.scheduledDates.length > 1 && new Date(renter?.scheduledDates[0]?.time).getMonth() === new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1]?.time).getMonth()) {
              fDate = monthNames[new Date(renter?.scheduledDates[0].time).getMonth()] + " " + new Date(renter?.scheduledDates[0].time).getDate()
              lDate = new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1].time).getDate() + ", " + new Date(renter?.scheduledDates[0].time).getFullYear()
              setScheduledDate(fDate + "-" + lDate)
            } else if (renter?.scheduledDates.length > 1 && new Date(renter?.scheduledDates[0]?.time).getMonth() !== new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1]?.time).getMonth()) {
              fDate = monthNames[new Date(renter?.scheduledDates[0].time).getMonth()] + " " + new Date(renter?.scheduledDates[0].time).getDate() + ", " + new Date(renter?.scheduledDates[0].time).getFullYear()
              lDate = monthNames[new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1].time).getMonth()] + " " + new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1].time).getDate() + ", " + new Date(renter?.scheduledDates[renter?.scheduledDates.length - 1].time).getFullYear()
              setScheduledDate(fDate + "-" + lDate)
            } else {
              setScheduledDate(monthNames[new Date(renter?.scheduledDates[0].time).getMonth()] + " " + new Date(renter?.scheduledDates[0].time).getDate() + ", " + new Date(renter?.scheduledDates[0].time).getFullYear())
            }
        }
        setTotal(renter?.scheduledDates[0].perDayTotal*renter?.scheduledDates.length)
    }, [])
    return (
        <div className={`${styles.Background} container `}>
            <div className={`row`}>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex}`}>
                    <img src={currentVehicle} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={` ${styles.ColorBlack}`}>{renter?.vehicleInfo?.name}</div>
                        <div className={styles.ColorGrey}>{renter?.vehicleInfo?.plateNumber}</div>
                    </div>
                </div>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                    <img src={currentTime} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={styles.ColorBlack}> {renter.spaceInfo.parkingRate.currency} {total}</div>
                        <div className={styles.ColorGrey}>{time}</div>
                    </div>
                </div>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                    <img src={currentOwner} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={styles.ColorBlack}>{renter?.renterInfo?.firstName} {renter?.renterInfo?.lastName}</div>
                        <div className={`${styles.ColorGrey} ${styles.Address}`}>{renter?.spaceInfo?.addressInfo?.fullAddress}</div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className={`${styles.ColorGrey} col-12`}>
                Scheduled Date on {scheduledDate} 
                </div>
                {/* <div className={`${styles.ColorGrey} col-12`}>Assigned Slot: {renter?.scheduledDates[0].slot}</div> */}
            </div>
        </div>
    )
}
export default ScheduledSpace
