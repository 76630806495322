import React from 'react'
import chadmo from '../../../assets/icons/charging-chademo.png'
import csseu from '../../../assets/icons/charging-css-eu.png'
import type2Cable from '../../../assets/icons/charging-type2-cable.png'
import schuko from '../../../assets/icons/charging-schuko.png'
function ChargingType(type:any) {
    
    switch(type.type){
        case 'CHAdeMO': return <img width={24} height={25.83} src={chadmo}/>
        case 'CCS EU': return <img width={24} height={25.83} src={csseu}/>
        case '7/3 Schuko': return <img width={24} height={25.83} src={schuko}/>
        case 'Type 2 Cable': return <img width={24} height={25.83} src={type2Cable}/>
        default: return <span >{type.type}</span>
    }
    return (
        <div>
            
        </div>
    )
}

export default ChargingType
