import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IResponse } from '../../../@customTypes/response'
import { getBookingScheduled } from '../../../endpoints/booking'
import CurrentSpace from '../CurrentSpace/CurrentSpace'
import styles from './CurrentSpaces.module.scss'
interface CurrentSpacesProps {
    spaceId: string,
    token: string
}
function CurrentSpaces({ spaceId, token }: CurrentSpacesProps) {
    const router = useHistory()
    const [spaceRenters, setSpaceRenters] = useState<any[]>([])
    const [bookingId, setBookingId] = useState("")
    useEffect(() => {
        // spaceRenters2 = []
        getBookingInScheduled('inProgress', "").then((res: any) => {
            if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
                mapScheduled(res)
                recursiveScheduled(res.lastEvaluatedKey.bookingId);
            }
        }).catch((e) => {
            console.log("e ",e);
            router.push('/notfound')
        })
    }, [])

    const recursiveScheduled = (bookingId2: any) => {
        setBookingId(bookingId2)
        getBookingInScheduled('inProgress', bookingId2).then((res: any) => {
            if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
               
                mapScheduled(res)
                recursiveScheduled(res.lastEvaluatedKey.bookingId)
                
            } else {
                // setTimeout(()=> {
                //     mapScheduled(res)
                // },1000)
                mapScheduled(res)
            }

        })

    }
    let spaceRenters22:any[] = []
    const mapScheduled = (res: any) => {
        if (res?.data.length) {
            res.data.map((res2: any) => {
                if(res2.status.includes("inProgress.parked") || res2.status.includes("inProgress.endParking") || res2.status.includes("inProgress.navigating")){
                    spaceRenters22.push(res2)
                }
                if(res2.status.includes("inProgress.scheduled")){
                    res2.scheduledDates.map((schedule)=>{
                        if(schedule.status === "inProgress"){
                            spaceRenters22.push(res2)
                        }
                    })
                }
            })
        }
        
        // spaceRenters22.sort((a, b) => (a.startTimestamp < b.startTimestamp) ? 1 : -1)
        setTimeout(() => {
            setSpaceRenters(spaceRenters22)
        }, 2500);
    }
    const getBookingInScheduled = (filter: any, key: any) => {
        return getScheduledBookings({ filterTag: filter, lastEvaluatedKey: key, limit: 10 }).then(res => {

            return res as IResponse
        });
    }

    const getScheduledBookings = (params: any = {}) => {
        return getBookingScheduled(spaceId, params, token)
    }
    return (
        <div>
           
            {
                spaceRenters.length !== 0 ? spaceRenters.map((renter, key) => {
                    return (
                        <div key={key}>
                            <CurrentSpace renter={renter} />
                        </div>
                    )
                }) : <div className={styles.NoActiveBackground}>No one parked in this space yet.</div>
            }
        </div>
    )
}

export default CurrentSpaces
