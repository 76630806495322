import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IBooking } from '../../../@customTypes/booking'
import { IResponse } from '../../../@customTypes/response'
import { getBookingScheduled } from '../../../endpoints/booking'
import HistorySpace from '../HistorySpace/HistorySpace'
import styles from './HistorySpaces.module.scss'
interface HistorySpaceProps {
    spaceId: string,
    token: string
}
function HistorySpaces({ spaceId, token }: HistorySpaceProps) {
    const router = useHistory()
    const [spaceRenters, setSpaceRenters] = useState<any[]>([])
    const [bookingId, setBookingId] = useState("")
    useEffect(() => {
        
        // spaceRenters2 = []
        getBookingInScheduled('completed', "").then((res: any) => {
            if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
                mapScheduled(res)
                recursiveScheduled(res.lastEvaluatedKey.bookingId);
            }
        }).catch((e) => {
            router.push('/notfound')
        })
        // getBookingInScheduled('inProgress.endParking', "").then((res: any) => {
        //     if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
        //         mapScheduled(res)
        //         recursiveScheduled(res.lastEvaluatedKey.bookingId);
        //     }
        // }).catch((e) => {
        //     router.push('/notfound')
        // })
    }, [])

    const recursiveScheduled = (bookingId2: any) => {
        setBookingId(bookingId2)
        getBookingInScheduled('completed', bookingId2).then((res: any) => {
            if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
                mapScheduled(res)
                recursiveScheduled(res.lastEvaluatedKey.bookingId)
            } else {
                mapScheduled(res)
            }

        })

        // getBookingInScheduled('inProgress.endParking', bookingId2).then((res: any) => {
        //     if (res.lastEvaluatedKey && res.lastEvaluatedKey.bookingId) {
        //         mapScheduled(res)
        //         recursiveScheduled(res.lastEvaluatedKey.bookingId)
        //     } else {
        //         mapScheduled(res)
        //     }

        // })

    }
    let spaceRenters22:any[] = []
    const mapScheduled = (res: any) => {
        if (res?.data.length) {
            res.data.map((res2: any) => {
                if(!res2.status.includes("inProgress")){
                    spaceRenters22.push(res2)
                }
                // spaceRenters22.sort((a, b) => (a.startTimestamp > b.startTimestamp) ? 1 : -1)

            })
        }
        
        // spaceRenters22.sort((a, b) => (a.startTimestamp < b.startTimestamp) ? 1 : -1)
        setTimeout(() => {
            setSpaceRenters(spaceRenters22)
        }, 2500);
    }
    const getBookingInScheduled = (filter: any, key: any) => {
        return getScheduledBookings({ filterTag: filter, lastEvaluatedKey: key, limit: 10 }).then(res => {

            return res as IResponse
        });
    }

    const getScheduledBookings = (params: any = {}) => {
        return getBookingScheduled(spaceId, params, token)
    }
    return (
        <div>
            {
                
                spaceRenters.length !== 0 ? spaceRenters.map((renter: IBooking, key: any) => {
                    return (
                        <div key={key}>
                            {
                                
                                <HistorySpace renter={renter} />
                            }
                        </div>
                    )
                }) : <div className={styles.NoActiveBackground}>No one parked in this space yet.</div>
            }
        </div>
    )
}

export default HistorySpaces
