import React, { useEffect, useState } from 'react'
import { IBooking } from '../../../@customTypes/booking'
import styles from './CurrentSpace.module.scss'
import currentVehicle from '../../../assets/svg/current-vehicle.svg'
import currentOwner from '../../../assets/svg/current-owner.svg'
import currentTime from '../../../assets/svg/current-time.svg'
interface CurrentSpaceProps {
    renter: IBooking
}
function CurrentSpace({ renter }: CurrentSpaceProps) {
    const helper:{[key:string]:string} = {
        completed: "Completed",
        cancelled: "EndParking",
        inProgress: "InProgress",
        navigating: "Navigating",
        scheduled: "InProgress"

    }
    const [startScheduled, setStartScheduled] = useState<any>()
    const [time, setTime] = useState('')
    
    const [progressTime, setProgressTime] = useState(0)
    useEffect(() => {

        if(renter.status === 'inProgress.scheduled'){
            renter?.scheduledDates.map(schedule=>{
                
                if(schedule.status === 'inProgress'){
                    setStartScheduled(schedule.time)
                }
            })
        }
        const durationTime = (+new Date().getTime() - renter.startTimestamp);
        let parkingTime = 0
        if ("" + renter.spaceInfo.freeParkingTime !== 'undefined') {
            parkingTime = renter.spaceInfo.freeParkingTime;
        }
        
        if(renter.status === 'inProgress.parked' || renter.status === 'inProgress.scheduled'){
            if (new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getUTCHours() && new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getMinutes()) {
                setTime(new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getUTCHours() + " hr and " + new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getMinutes() + " mins")
            } else if (new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getUTCHours()) {
                setTime(new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getUTCHours() + ' hr')
            } else {
                setTime(new Date(new Date().getTime() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp)).getMinutes() + ' mins')
            }
        } else {
            setTime(new Date(new Date(renter?.endTimestamp).getTime() - renter.startTimestamp).getUTCHours() + " hr and " + new Date(new Date(renter?.endTimestamp).getTime() - renter.startTimestamp).getMinutes() + " mins")
            
        }
        if (renter.status === 'inProgress.parked' || renter.status === 'inProgress.scheduled') {
            const duration = new Date(+new Date() - (renter.status === 'inProgress.scheduled'?startScheduled:renter.startTimestamp))
            const time = ((Number(duration) - parkingTime) / (1000 * 60 * 60));
            const finalDuration = (time * renter.spaceInfo.parkingRate.value)
            if(durationTime > parkingTime){
                setProgressTime(finalDuration);
            }
            
        } else {
            setProgressTime(renter?.transactionSummary.rawPrice+renter?.transactionSummary.tax);
        }

    }, [startScheduled])
    console.log("schedule ",renter.status);

    return (
        <div className={`${styles.Background} container `}>
            <div className={`row`}>
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex}`}>
                    <img src={currentVehicle} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={` ${styles.ColorBlack}`}>{renter?.vehicleInfo?.name}</div>
                        <div className={styles.ColorGrey}>{renter?.vehicleInfo?.plateNumber}</div>
                    </div>
                </div>
                {
                    renter.status !== 'inProgress.navigating' ? 
                    <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                        <img src={currentTime} alt="alt" width={49} height={49} />
                        <div className="px-2">
                            <div className={styles.ColorBlack}> {renter.spaceInfo.parkingRate.currency} {progressTime.toFixed(2)}{renter.status === 'inProgress.scheduled'&&' (Paid)'}</div>
                            <div className={styles.ColorGrey}>{time}</div>
                        </div>
                    </div> : 
                    <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                        <img src={currentTime} alt="alt" width={49} height={49} />
                        <div className="px-2 pt-3">
                            Navigating
                        </div>
                    </div>
                }
                <div className={`col-12 col-md-4 col-sm-6 ${styles.DisplayFlex} `}>
                    <img src={currentOwner} alt="alt" width={49} height={49} />
                    <div className="px-2">
                        <div className={styles.ColorBlack}>{renter?.renterInfo?.firstName} {renter?.renterInfo?.lastName}</div>
                        <div className={`${styles.ColorGrey} ${styles.Address}`}>{renter?.spaceInfo?.addressInfo?.fullAddress}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className={`${styles.ColorGrey} col-12`}>Parking In Progress</div> */}
                Parking is {helper[renter?.status === 'inProgress.parked' ? "inProgress" : renter?.status === 'inProgress.endParking' ? 'endParking' : renter?.status === 'inProgress.scheduled' ? 'scheduled' : 'navigating']}

                {/* <div>
                {renter?.startTimestamp}
                </div> */}
                {/* <div>{renter?.endTimestamp}</div> */}
                {/* <div className={`${styles.ColorGrey} col-12`}>Start: {new Date(renter?.startTimestamp).getUTCHours()}:{new Date(renter?.startTimestamp).getUTCMinutes()}, {new Date(renter?.startTimestamp).getFullYear()}-{new Date(renter?.startTimestamp).getMonth()+1}-{new Date(renter?.startTimestamp).getDate()}</div>
                <div className={`${styles.ColorGrey} col-12`}>End: {new Date(renter?.endTimestamp).getUTCHours()}:{new Date(renter?.endTimestamp).getUTCMinutes()}, {new Date(renter?.endTimestamp).getFullYear()}-{new Date(renter?.endTimestamp).getMonth()+1}-{new Date(renter?.endTimestamp).getDate()}</div>
                <div className={`${styles.ColorGrey} col-12`}>Assigned Slot: {renter?.assignedSlot.slotName}</div> */}
            
            </div>
            
        </div>
    )
}

export default CurrentSpace
