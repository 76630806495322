
import styles from './PageNotFound.module.scss'
function PageNotFound(){
    return (
        <div className={styles.Container}>
            <div className={styles.Font}>Error 404|</div>
        </div>
    )
}

export default PageNotFound
