import './styles//global.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from 'react';
import SpaceDetails from './pages/SpaceDetails/SpaceDetails';
import Header from './components/Common/header/Header';
import PageNotFound from './pages/PageNotFound/PageNotFound';
function App() {
  return (
    <Router>
      <div >
        <Switch>

          <Route path="/space/:spaceId" exact>
            <div className="sticky-top">
              <Header />
            </div>
            <SpaceDetails />
          </Route>
          <Route path="/notfound">
            <PageNotFound />
          </Route>
          <Route path="/"
            component={() => {
              global.window && (global.window.location.href = 'https://parkit.as');
              return null;
            }
            }
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
