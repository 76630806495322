import axios from "axios";
import { ISpace } from "../@customTypes/space";
import { environment } from "../environment";

export function getSpace(spaceId: string, token: string) {
    return axios
        .get(environment.baseUrl+'space/' + spaceId, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '2ce81eb0-a49a-11ea-b556-f7c9f9f425bc',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            const data = response.data.data;
            return data as ISpace;
        })
        .catch((e) => {
            throw e;
        });
}


export function getSlots(spaceId: string, token: string) {
    return axios
        .get(environment.baseUrl+"slot/"+spaceId, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '2ce81eb0-a49a-11ea-b556-f7c9f9f425bc',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            const data = response.data;
            return data;
        })
        .catch((e) => {
            throw e;
        });
}



