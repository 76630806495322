import React from 'react'
import styles from './Modal.module.scss'
export interface ModalProps {
    showModal: boolean;
    setShowModal: (val: boolean) => void;
    children?: React.ReactNode;
}
function Modal({ showModal, children, setShowModal }: ModalProps) {
    return (
        <div>

            {showModal ? (
                <div className={styles.OverlayStyles}>
                    <div className={styles.Modal}>
                        {children ? children : null}
                    </div>
                </div>
            ) : null}

        </div>
    )
}

export default Modal
