import React from 'react'
import house from '../../../assets/icons/legend-H.svg'
import parking from '../../../assets/icons/legend-P.svg'
import condominium from '../../../assets/icons/legend-C.svg'
import business from '../../../assets/icons/legend-B.svg'
function ParkingType(type:any) {
    
    switch(type.type){
        case 'house': return <img src={house}/>
        case 'parkingBuilding': return <img src={parking}/>
        case 'businessEstablishment': return <img src={business}/>
        case 'condominium': return <img src={condominium}/>
    }
    return (
        <div>
            
        </div>
    )
}

export default ParkingType
