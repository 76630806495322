import { report } from "process";

export const environment = {
  baseUrl: "",
};

if (process.env.NODE_ENV === "development") {
  environment.baseUrl =
    // "https://wmkz0sb179.execute-api.ap-southeast-1.amazonaws.com/stg/";\
    "https://9u2va8h0eh.execute-api.ap-southeast-1.amazonaws.com/prod/";
}

if (process.env.NODE_ENV === "production") {
  environment.baseUrl =
    "https://9u2va8h0eh.execute-api.ap-southeast-1.amazonaws.com/prod/";
  // "https://wmkz0sb179.execute-api.ap-southeast-1.amazonaws.com/stg/";
}
