import axios from "axios";
import {environment} from '../environment'
export function getBookingScheduled(spaceId: string, params: any,token:string) {
    
    return axios
        .get(environment.baseUrl+"booking/space/"+spaceId, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '2ce81eb0-a49a-11ea-b556-f7c9f9f425bc',
                Authorization: `Bearer ${token}`
            },
            params: params

        })
        .then((response: any) => {
            const data = response.data;
            return data
        })
        .catch((e) => {
            throw e;
        });
}


